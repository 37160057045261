<script setup lang="ts">
definePageMeta({
  layout: "home",
});
const colorMode = useColorMode();
colorMode.preference = "light";
</script>

<template>
  <head>
    <title>QuoteIQ CRM</title>
  </head>
  <div class="h-screen"></div>
</template>
